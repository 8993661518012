import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { MaxWidth } from 'components/global'
import SingleBlog from 'components/shared/singleblog'
import { media } from 'utils/media'

import ArrowIcon from 'svgs/chevron.svg'

const BlogsStyles = styled.section`
  width: 100%;
  background: var(--offWhite);
  padding-bottom: 2.5rem;
  > section {
    max-width: 1200px;
    @media ${media.md} {
      padding-bottom: 15rem;
    }
    @media ${media.xl} {
      padding-bottom: 14rem;
    }
    @media ${media.xxl} {
      padding-bottom: 16rem;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      max-width: 350px;
      h4 {
        text-align: center;
        color: var(--darkGrey);
        font-size: 1.85rem;
        @media ${media.md} {
          text-align: left;
          font-size: 2.5rem;
        }
      }
      .icon {
        width: 25px;
        height: 25px;
        border: solid 2px var(--teal);
        border-radius: 50px;
        transition: all 0.25s ease-in-out;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.25rem;
          svg {
            transition: all 0.25s ease-in-out;
            fill: var(--teal);
            width: 100%;
            height: 100%;
            padding-left: 1px;
            padding-top: 0.5px;
          }
        }
      }
      &:hover,
      &:active {
        .icon {
          border-color: var(--pink);
          a svg {
            fill: var(--pink);
            animation: arrow 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }
        }
      }
    }
    .wrapper {
      margin: 2rem 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.25rem;
      @media ${media.md} {
        grid-template-columns: repeat(2, 1fr);
      }
      @media ${media.lg} {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .btns {
      width: 90%;
      margin: var(--auto);
      text-align: center;
      padding-top: 1rem;
    }
  }
`
const Blogs = ({ data }) => (
  <BlogsStyles>
    <MaxWidth>
      <section>
        <div className="title">
          <Link to="/news/">
            <h4>Latest news</h4>
          </Link>
          <div className="icon">
            <Link to="/news/">
              <ArrowIcon />
            </Link>
          </div>
        </div>
        <div className="wrapper">
          {data.map((blog, i) => (
            <SingleBlog data={blog} key={i} />
          ))}
        </div>
      </section>
    </MaxWidth>
  </BlogsStyles>
)

export default Blogs
