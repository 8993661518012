import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { MaxWidth } from 'components/global'
import { WhereToWatch } from 'components/Modals'

import { media } from 'utils/media'

import Stripes from 'svgs/stripes.svg'
import Crosses from 'svgs/crosses.svg'
import ModalContext from 'context/ModalContext'

const IntroStyles = styled.section`
  width: 100%;
  background: var(--offWhite);
  padding: var(--defaultPadding);
  .about {
    color: var(--white);
    text-align: center;
    position: relative;
    z-index: 5;
    padding-bottom: 1.5rem;
    .background {
      position: relative;
      padding: 3rem 4rem;
      background: var(--darkGrey);
      border-radius: 10px;
      max-width: 1100px;
      margin: 0 auto;
      overflow: hidden;
      #crosses {
        fill: white !important;
        width: 150px !important;
        g {
          stroke: white !important;
          polygon {
            stroke: white !important;
          }
          g {
            g {
              g {
                stroke: white !important;
              }
            }
          }
          polygon: {
            fill: white !important;
            stroke: white !important;
          }
        }
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
      #stripes {
        position: absolute;
        bottom: 0;
        left: -5rem;
        width: 200px;
      }
    }
    h2 {
      margin: 1.5rem 0;
    }
    p {
      margin-top: 1rem;
      max-width: 1000px;
      margin: 0.85rem auto;
      font-size: 19px;
      @media ${media.xl} {
        font-size: 20px;
      }
    }
    a,
    span {
      cursor: pointer;
      font-weight: 700;
      &:hover,
      &:active {
        color: var(--teal);
        text-decoration: underline;
      }
    }
    .blue {
      color: var(--blue);
    }
    .purple {
      color: var(--purple);
    }
    .pink {
      color: var(--pink);
    }
    .teal {
      color: var(--teal);
    }
    .buttons {
      max-width: 500px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      margin: 2.5rem auto 1rem auto;
      text-align: center;
      @media ${media.md} {
        grid-template-columns: 1fr 1fr;
        /* max-width: 550px; */
        .last:nth-last-child(1):nth-child(odd) {
          grid-column: 1 / span 2;
        }
      }
      @media ${media.lg} {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 900px;
        .last:nth-last-child(1):nth-child(odd) {
          grid-column: 3;
        }
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 1rem;
    /* max-width: 350px; */
    h4 {
      text-align: center;
      color: var(--darkGrey);
      font-size: 1.85rem;
      @media ${media.md} {
        text-align: left;
        font-size: 2.5rem;
      }
    }
    .icon {
      width: 25px;
      height: 25px;
      border: solid 2px var(--pink);
      border-radius: 50px;
      transition: all 0.25s ease-in-out;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        svg {
          transition: all 0.25s ease-in-out;
          fill: var(--pink);
          width: 100%;
          height: 100%;
          padding-left: 1px;
          padding-top: 0.5px;
        }
      }
    }
    &:hover,
    &:active {
      .icon {
        border-color: var(--teal);
        a svg {
          fill: var(--teal);
          animation: arrow 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
      }
    }
  }
`
const Intro = () => {
  const { open, setOpen } = useContext(ModalContext)
  return (
    <IntroStyles>
      <MaxWidth>
        <div className="about">
          <div className="background">
            <p>England have retained their Arnold Clark Cup title!</p>

            <p>
              Check out the highlights of the 2023 tournament{' '}
              <Link to="/2023/">here</Link> and make sure you’ve signed up{' '}
              <span onClick={() => setOpen(true)}> here</span> to find out about
              upcoming events.
            </p>
            <p>
              Thank you to everyone who supported the Arnold Clark Cup 2023, we
              will see you again soon!
            </p>
            <Stripes id="stripes" />
            <Crosses id="crosses" />
          </div>
          {/* <p>
            The inaugural <strong>Arnold Clark Cup</strong> saw{' '}
            <strong className="blue">England</strong> take a dramatic
            last-minute victory to seal the first ever title, beating off
            competition from other top 10 FIFA ranked teams{' '}
            <strong className="purple">Canada</strong>,{' '}
            <strong className="teal">Germany</strong> and{' '}
            <strong className="pink">Spain</strong>.
          </p>
          <p>
            <span>England</span> will be back in <strong>February 2023</strong>{' '}
            to defend their title. Details of their opposition and the host
            venues to come. Watch this space!
          </p> */}
          {/* <p>
            The&nbsp;<strong>Arnold Clark Cup is back!&nbsp;</strong>
            <strong className="blue">England</strong>&nbsp;will look to defend
            their title in February 2023 against three new challengers:&nbsp;
            <strong className="purple">
              Belgium, <span className="teal">Italy</span>
            </strong>
            &nbsp;and&nbsp;
            <strong className="pink">Korea Republic</strong>.&nbsp;
          </p> */}
          {/* <p>
            <strong className="blue">Stadium MK</strong>&nbsp;(Milton
            Keynes),&nbsp;
            <strong className="teal">Ashton Gate Stadium</strong>
            &nbsp;(Bristol) and a venue yet to be finalised, will set the stage
            for three thrilling
            <strong>&nbsp;double-header&nbsp;</strong>matchdays as part of a
            round-robin format.
          </p> */}
          {/* <p>
            <strong className="blue">Stadium MK</strong> (Milton Keynes),{' '}
            <strong className="pink">Ashton Gate Gate Stadium</strong> (Bristol)
            and the{' '}
            <strong className="teal">Coventry Building Society Arena</strong>{' '}
            (Coventry), will set the stage for three thrilling{' '}
            <strong>double-header</strong> matchdays as part of a round-robin
            format.
          </p>
          <p>
            <Link to="/tickets/">
              <strong>Get your&nbsp;tickets now&nbsp;</strong>
            </Link>
            to see if the Lionesses can continue their winning run!
          </p> */}
          {/* <div className="buttons">
            <Link to="/ambassadors/">
              <button type="button" className="btn btn-main">
                <span>Our Ambassadors</span>
              </button>
            </Link>
            <div>
              <button
                onClick={() => setOpen(true)}
                onKeyDown={() => setOpen(true)}
                type="button"
                className="btn btn-main"
              >
                <span>Where to Watch</span>
              </button>
            </div>

            <Link to="/venue-info/" className="last">
              <button type="button" className="btn btn-main">
                <span>Venue Info</span>
              </button>
            </Link>
          </div> */}
        </div>
        {/* <div className="title">
          <Link to="/tickets/">
            <h4>Buy tickets</h4>
          </Link>
          <div className="icon">
            <Link to="/tickets/">
              <ArrowIcon />
            </Link>
          </div>
        </div> */}
        {/* {open ? <WhereToWatch open={open} setOpen={setOpen} /> : ''} */}
      </MaxWidth>
    </IntroStyles>
  )
}

export default Intro
