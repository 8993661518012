import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'

const SecondHeroStyles = styled.section`
  width: 100%;
  background-color: var(--offWhite);
  div {
    margin: 0 auto;
    h2 {
      color: var(--darkGrey);
      text-align: center;
      padding-top: 3rem;
      font-size: 2.5rem;
      span {
        color: var(--yellow);
      }
      @media ${media.md} {
        font-size: 3.5rem;
      }
      @media ${media.xl} {
        font-size: 4rem;
      }
    }
    .img {
      .mobile {
        @media ${media.md} {
          display: none;
        }
      }
      .desktop {
        display: none;
        margin: 3rem auto 0 auto;
        @media ${media.md} {
          display: block;
        }
        @media ${media.xl} {
          text-align: center;
          margin: 4rem auto 0 auto;
        }
      }
    }
  }
`
const SecondHero = () => (
  <SecondHeroStyles>
    <div>
      <h2>
        THE TEAMS <span>2023</span>
      </h2>
      <div className="img">
        <div className="desktop">
          <StaticImage src="../../../images/sh1.png" alt="" />
        </div>
        <div className="mobile">
          <StaticImage src="../../../images/SH.png" alt="" />
        </div>
      </div>
    </div>
  </SecondHeroStyles>
)

export default SecondHero
