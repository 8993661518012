import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { media } from 'utils/media'

const FixturesStyles = styled.div`
  width: 100%;
  background: var(--offWhite);
  padding-bottom: 3.5rem;
  section {
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;
    .desktop {
      display: none;
      margin: 0 auto;
      text-align: center;
      @media ${media.md} {
        display: block;
      }
    }
    .mobile {
      @media ${media.md} {
        display: none;
      }
    }
  }
`
const Fixtures = () => (
  <FixturesStyles>
    <section>
      <div className="desktop">
        <StaticImage src="../../../images/fix-desktop.jpg" alt="" />
      </div>
      <div className="mobile">
        <StaticImage src="../../../images/fix-mob.jpg" alt="" />
      </div>
    </section>
  </FixturesStyles>
)

export default Fixtures
