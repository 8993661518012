import { MaxWidth } from 'components/global'
import React from 'react'
import styled from 'styled-components'

const VideoStyles = styled.section`
  width: 100%;
  background: var(--offWhite);
  padding: 0 0 4rem 0;
  .video {
    max-width: 1100px;
    margin: 0 auto;
  }
`
const Video = () => (
  <VideoStyles>
    <MaxWidth>
      <div className="video">
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/810557498?h=731c637307&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
            title="07MAR23_ACC_WRAP_FINAL_16-9 (1).mp4"
          />
        </div>
        <script src="https://player.vimeo.com/api/player.js" />
      </div>
    </MaxWidth>
  </VideoStyles>
)

export default Video
