import React, { useRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import GetImage from 'components/functional/getImage'
import { media } from 'utils/media'

const SingleBlogStyles = styled.article`
  position: relative;
  z-index: 999999;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
  border-radius: 5.5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.25s ease-in-out;
  border-top-left-radius: 5.5px;
  border-top-right-radius: 5.5px;
  &:nth-child(3n + 1) {
    .gradient {
      background: linear-gradient(
        90deg,
        rgba(255, 222, 0, 1) 0%,
        rgb(212, 21, 104) 100%
      );
    }
  }
  &:nth-child(3n + 2) {
    .gradient {
      background: linear-gradient(
        90deg,
        rgb(212, 21, 104) 0%,
        rgb(0, 180, 187) 100%
      );
    }
  }
  &:nth-child(3n + 3) {
    .gradient {
      background: linear-gradient(
        90deg,
        rgb(0, 180, 187) 0%,
        rgba(135, 24, 157, 1) 100%
      );
    }
  }

  .gradient {
    width: 100%;
    height: 7px;
    transition: all 0.25s ease-in-out;
    overflow-x: hidden;
    position: relative;

    section {
      transition: all 1.5s ease-in-out;
      width: 700%;
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      /* background: linear-gradient(
        90deg,
        rgba(255, 222, 0, 1) 0%,
        rgb(212, 21, 104) 25%,
        #00a3e0 50%,
        rgba(135, 24, 157, 1) 75%,
        rgb(0, 180, 187) 100%
      ); */
    }
  }
  .img {
    overflow: hidden;
    border-top-left-radius: 5.5px;
    border-top-right-radius: 5.5px;
    max-height: 240px;
    .gatsby-image-wrapper {
      object-fit: fill !important;
      border-top-left-radius: 5.5px;
      border-top-right-radius: 5.5px;
      transition: transform 0.35s ease-in-out;
      @media ${media.md} {
        min-height: 15rem;
        max-height: 200px;
      }
      @media ${media.xl} {
        max-height: 240px;
      }
    }
  }
  .body {
    margin: 1.5rem 1rem;
    p,
    h6 {
      color: var(--darkGrey);
    }
    h6 {
      font-weight: 700;
      line-height: 1.5rem;
      text-transform: uppercase;
      margin: 0.25rem 0;
      font-size: 1.25rem;
    }
    .description {
      margin-top: 0.5rem;
    }
    .meta {
      margin-top: 0.25rem;
      font-weight: 500;
      color: #878787;
      text-transform: uppercase;
      font-size: 1rem;
      span {
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
  &:hover,
  &:active {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    .img {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }
    .gradient {
      /* section {
        transition: transform 0.5s ease-in-out;
        animation: gradient 5.5s infinite backwards;
      } */
    }
  }
`
const SingleBlog = ({ data }) => {
  const singleblogRef = useRef()
  return (
    <SingleBlogStyles>
      <Link to={`/news/${data.slug.current}/`}>
        <div className="img">
          <GetImage
            data={data.mainImage.asset}
            alt={`${data.title} | Arnold Clark Cup`}
          />
        </div>
        <div className="gradient">
          <section />
        </div>
        <div className="body">
          <h6>{data.title}</h6>
          <p className="meta">{data.publishedAt}</p>
        </div>
      </Link>
    </SingleBlogStyles>
  )
}

export default SingleBlog
