import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { Banner, Header, Layout, SocialBanner } from 'components/global'
import { Intro, Video } from 'components/Blocks'
import Blogs from 'components/index/blogs'

const IndexPage = ({ data }) => {
  const blogs = data.Blogs.nodes

  return (
    <Layout>
      <Header />
      <SocialBanner />
      {/* <SecondHero /> */}
      <Intro />
      <Video />
      {/* <Fixtures /> */}
      <Blogs data={blogs} />
      <Banner />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query BlogQuery {
    Blogs: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      nodes {
        title
        description
        publishedAt(formatString: "MMM DD YYYY")
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
